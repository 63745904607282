import { useState } from "react";
import React from 'react';

import { WEB3AUTH_NETWORK_TYPE } from "./config/web3AuthNetwork";
import { CHAIN_CONFIG_TYPE } from "./config/chainConfig";
import { Web3AuthProvider } from "./services/web3auth";
import Main from "./pages/main";

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/graphics.css';
import './styles/globals.css';
import './styles/header.css';
import './styles/mobile.css';
import './styles/footer.css';

import Layout from "./components/layout";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


function App() {

  const [web3AuthNetwork, setWeb3AuthNetwork] = useState<WEB3AUTH_NETWORK_TYPE>("cyan");
  const [chain, setChain] = useState<CHAIN_CONFIG_TYPE>("polygon");
  const { t, i18n } = useTranslation();

  return (
    <React.StrictMode>
    <div className="App">
      <Web3AuthProvider chain={chain} web3AuthNetwork={web3AuthNetwork}>
       <Layout>       
        <Main/>        
       </Layout>
      </Web3AuthProvider>
    </div>
    </React.StrictMode>
  );
}

export default App;
