import { useEffect, useState } from "react";
import Tweet from "../components/twitter";
import { useWeb3Auth } from "../services/web3auth";
import { toast, ToastContainer } from "react-toastify";
import { Button, Col, Container, Row } from "react-bootstrap";
import Followus from "../components/followus";
import WaitlistForm from "../components/waitlistForm";
import { useTranslation, Trans } from 'react-i18next';
const Main = () => {

  const [user, setUser] = useState<string | undefined>(undefined);
  const [isLogged, setLogged] = useState<Boolean>(false);
  const [isMetamask, setMetamask] = useState<Boolean>(false);

  const { provider, web3Auth, login, getAccounts } = useWeb3Auth();

  const { t } =   useTranslation();
  useEffect(() => {
    const init = async () => {
      const addr = await getAccounts();
      if(addr) {
        setUser(addr);
        if (web3Auth.connectedAdapterName == "metamask") {
          setMetamask(true);
        }
        setLogged(true);
      }
      
    }
    if(provider) init();
  }, [provider])


  const logUser = async () => {
    await login();
    setLogged(true);
  }


  return (
    <>      
      <Container className="waiting-wrapper"> 
      <div className='lang-selector'>
        <div className='lang-select'>
        <div className='lang'>
          <a href="?lang=en">EN</a> 
          </div>
        <div className='globe all'></div>
        <div className='lang'>
          <a href="?lang=fr">FR</a> 
        </div>
        </div>
      </div>
      <div></div>
        <div> <a href="https://wonderlive.io"><img src="./images/logo_primaire-blanc.png" alt="logo" className="logo img-fluid  mx-auto"/></a></div>
        <div> <strong>{t('title.text1')}</strong><p><br></br>{t('title.text2')}</p></div>
          <div className="d-flex justify-content-around">
          { isLogged ?  <div >
          <WaitlistForm metamask={isMetamask}/>
          </div> :
          <div>
            <Button style={{height : "50px"}} onClick={logUser} className="btn-cta-sell btn-lg btn">
            {t('login.button')}
            </Button>
          </div>}
        </div>
      </Container>
      <Container>
        <div className="help">
          <h2><strong>{t('howitwork.title')}</strong></h2>
          <p>{t('howitwork.description')}</p>
          <Row>
            <Col xs={12} lg={4}>
                <div className="card h-100 w-100 d-flex align-items-center">
                    <h3><strong>{t('howitwork.bloc1.title')}</strong></h3>
                    <p>{t('howitwork.bloc1.content')} </p>
                </div>
            </Col>
            <Col className="margin-mobile" xs={12} lg={4}>
            <div className="card h-100 w-100 d-flex align-items-center">
                    <h3><strong>{t('howitwork.bloc2.title')}</strong></h3>
                    <p>{t('howitwork.bloc2.content')} </p>
                </div>
              </Col>
              <Col  xs={12} lg={4}>
                <div className="card h-100 w-100 d-flex align-items-center">
                  <span>
                  <h3><strong>{t('howitwork.bloc3.title')}</strong></h3>
                    <p>{t('howitwork.bloc3.content')}<br></br>
                    <Followus />
                    </p>
                  </span>
                </div>
              </Col>
          </Row>
         </div>  
      </Container>
      </>

  );
};

export default Main;
