import React, { Component } from 'react';
import {

  List,
  ListInlineItem,

} from "reactstrap";


class Followus extends Component {

  render() {

    return <List type="inline" className="follow-us-social" >
      <ListInlineItem><a href="https://www.linkedin.com/company/wonderlive/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin"></i></a></ListInlineItem>
      <ListInlineItem><a href="https://twitter.com/wonderlive_io" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></ListInlineItem>
      <ListInlineItem><a href="https://medium.com/@contact_86897" target="_blank" rel="noreferrer"><i className="fab fa-medium"></i></a></ListInlineItem>
      <ListInlineItem><a href="https://discord.gg/p5NRFsN9x7" target="_blank" rel="noreferrer"><i className="fab fa-discord"></i></a></ListInlineItem>
      <ListInlineItem><a href="https://t.me/+d5lt3LDUpAhkODI0" target="_blank" rel="noreferrer"><i className="fab fa-telegram"></i></a></ListInlineItem>
      <ListInlineItem><a href="https://www.instagram.com/wonderliveapp/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></ListInlineItem>
    </List>
  
  };
}



export default Followus;
