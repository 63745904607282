import {  useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import trimAddress from '../utils/address';
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { useWeb3Auth } from "../services/web3auth";
import { useTranslation, Trans } from 'react-i18next';

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


const WaitlistForm = (props) => {

  const { t } =   useTranslation();

const { getUserInfo, provider, logout, getAccounts } = useWeb3Auth();
const [register, setRegister] = useState({instagram: '', twitter:'',facebook:'' ,email:'', wallet:''})
const [isFinish, setFinish] = useState(false);
const [blocking,setBlocking] = useState(false);
const [wallet, setWallet] = useState<string>("");
const [RGPDok  , setRGPDok] = useState(false);

let verify_msg = 'Join%20the%20decentralized%20revolution%21%20Be%20one%20of%20the%20first%20to%20experience%20the%20future%20of%20social%20media.%20%0AGet%20early%20access%20to%20%40wonderlive_io%0A%0ASign%20up%20now%20on%20app.wonderlive.io%20and%20be%20a%20part%20of%20shaping%20the%20future%20of%20social%20media%21%0A%0A' 

useEffect(() => {
  /// check if we use openLogin (to get the user email)
  const init = async () => {
    const addr = await getAccounts();
    setWallet(addr);
    if(!props.metamask){
      const userInfo = await getUserInfo();
      setRegister({ ...register, email: userInfo.email });
    }
  }
  if (provider) init();
}, []);


const saveWaitlist = async (params) => {
  try {
      await axios.get(process.env.REACT_APP_WAITLIST_API_URL,  { params });
  } catch (error) {
    console.log(error);
  }
}

const  objectToGetParams = (object: {
  [key: string]: string | number | undefined | null;
}) => {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
}

const facebookLink = (url: string, { hashtag }: { hashtag?: string }) => {
  return (
    'https://www.facebook.com/sharer/sharer.php' +
    objectToGetParams({
      u: url,
      hashtag,
    })
  );
}

  const handleChange = (e) => {
    setRegister({ ...register, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    register.wallet = wallet;

    
    if (!register.email) {
      toast.error("Please enter your email address !");
      return false;
    }
    // check if the user has entered at least one social media account
    if (!register.instagram && !register.twitter && !register.facebook){
      toast.error("Please enter at least one social media account !");
      return false;
    }

    setBlocking(true);

    try {
      register.wallet = wallet;
      setRegister(register);      
      await saveWaitlist(register);
      setTimeout(async () => {
        ShareTwitter();
        await handleSharing();
        setFinish(true);
        setBlocking(false);
      }, 5000);     
     } catch (error) {
     console.log(error);
     setBlocking(false);
    }
  };

  const handleSharing = async () => {

    let text = 'Join the decentralized revolution! Be one of the first to experience the future of social media.\nGet early access to @wonderlive_io\n\n Sign up now on https://app.wonderlive.io and be a part of shaping the future of social media!\n\n';

    const shareDetails = { url:'https://wonderlive.io', title:'Wonderlive', text:`${text} ${trimAddress(wallet)}` };
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      ShareFacebook();
    }
  };

  const ShareTwitter = () => {
   let link = `https://twitter.com/intent/tweet?text=${verify_msg} ${trimAddress(wallet)}`;
    window.open(link, '_blank', 'noreferrer');
  }

  const ShareFacebook = () => {
    let link = facebookLink('https://wonderlive.io',{hashtag: '#wonderliveapp'});
     window.open(link, '_blank', 'noreferrer');
   }

  const handleCheckBoxRGPDChange = () => {
    setRGPDok(!RGPDok);

  }

  return (
    <>

<BlockUi tag="div" blocking={blocking}>
     { !isFinish ? 
    
     <>
      <p>{t('form.notice')}</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="instagram">
          <Form.Control
            type="text"
            placeholder="instragam @"
            name="instagram"
            className="bg-transparent"
            value={register.instagram}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="twitter">
          <Form.Control
            type="text"
            placeholder="twitter #"
            name="twitter"
            className="bg-transparent"
            value={register.twitter}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="facebook">
          <Form.Control
            type="text"
            placeholder="facebook id"
            name="facebook"
            className="bg-transparent"
            value={register.facebook}
            onChange={handleChange}
          />
        </Form.Group>

        {
          props.metamask ? (
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="email"
                placeholder="Entrez votre email ex:john.doe@example.com"
                name="email"
                className="bg-transparent"
                value={register.email}
                onChange={handleChange}
              />
          </Form.Group>
          ) : 
          (
            <></>
          )
        }
<Form.Group className="mb-3 rgpd" controlId="rgpd">
       <Form.Check 
            type="checkbox"
            id="rgpd"
            label={t('form.rgpd')}
            onChange={handleCheckBoxRGPDChange}
          /></Form.Group>
       { RGPDok ? 
        <Button className="btn-cta-sell btn btn-secondary btn-lg" type="submit">{t('form.submitbtn')}</Button>
        :
       
        <Button className="disabled btn-cta-sell btn btn-secondary btn-lg" type="submit" disabled>{t('form.submitbtn')}</Button>
       }
      </Form> 
      </>
      : <div><strong>{t('form.thankyou')}</strong></div>


      }


      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        theme='colored'
        rtl={false} />
      </BlockUi>

     
    </>
  );
}

export default WaitlistForm;