import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './i18n';

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);


root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
